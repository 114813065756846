/*Tags*/
html {
  background: #222;
  margin: 0px;
  width: 100%;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

body {
  margin: 0px;
  width: 100%;
}

h1,
h2 {
  color: #ee7879;
  overflow-wrap: break-word;
  margin: 0;
}

p {
  color: #09c372;
  overflow-wrap: break-word;
}

li {
  color: #09c372;
  font-size: 18px;
  text-decoration: none;
}
li:hover {
  color: #ee7879;
  transform: scale(1.3);
  transition: 0.1s;
  cursor: pointer;
}

input {
  background-color: #222;
  border: solid 1px #09c372;
  border-radius: 7px;
  color: #ee7879;
  text-align: center;
  height: 40px !important;
  width: 250px;
  font-size: 15px;
}

input:focus,
input::placeholder {
  color: #ee7879;
  text-align: center;
}

footer {
  background-color: #2a3166;
  border-top: solid 1px #ee7879;
  display: flex;
  height: 50px;
  justify-content: space-between;
}

select {
  background-color: #222;
  border: solid 1px #09c372;
  border-radius: 7px;
  color: #ee7879;
  height: 40px;
  padding: 5px;
  text-align: center;
  width: 250px;
}

/*Classes*/
.mainContainer {
  background: #222;
}

.App {
  text-align: center;
  background-color: #222;
  margin: 0px;
}

.mtg-card {
  margin: 10px;
  width: 350px;
}

.page-container {
  color: #09c372;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 20px;
}

.static-card {
  background-color: #2a3166;
  box-shadow: 0px 0px 5px #09c372;
  border-radius: 7px;
  color: #09c372;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  padding: 5px;
  width: 400px;
}

/*Styles for Loading Symbol*/
.loading-symbol {
  border: 16px solid #ee7879;
  border-top: 16px solid #09c372;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Styles for modal*/
.modal-container {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #2a3166;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 350px;
  height: 400px;
}

/*Buttons*/
.submit-btn {
  background-color: #09c372;
  border-radius: 7px;
  color: black;
  margin: 0 3px 0 3px;
  padding: 5px 10px 5px 10px;
  height: 40px;
  width: 100px;
}
.submit-btn:hover {
  background-color: #ee7879;
  border: solid 1px #09c372;
  color: white;
  transform: scale(0.9);
  transition: 0.1s;
}

.cancel-btn {
  background-color: red;
  color: white;
  border-radius: 7px;
  color: white;
  margin: 0 3px 0 3px;
  padding: 5px 10px 5px 10px;
  height: 40px;
  width: 100px;
}
.cancel-btn:hover {
  background-color: #333;
  border: solid 1px red;
  transform: scale(0.9);
  transition: 0.1s;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.footer-form {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

@media only screen and (max-width: 1075px) {
  .static-card {
    width: 350px;
  }

  .mtg-card {
    width: 300px;
  }

  .footer-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .mobile-select {
    width: 100px;
  }
}
