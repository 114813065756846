.brand {
  align-items: center;
  display: flex;
  font-weight: bold;
  padding-left: 10px;
}

.hamburger-icon {
  cursor: pointer;
  display: none;
  margin: auto 0;
}

.hamburger-icon div {
  background-color: #09c372;
  height: 3px;
  margin: 6px 0;
  transition: 0.3s;
  width: 35px;
}

header {
  background-color: #2a3166;
  border-bottom: solid 1px #ee7879;
  display: flex;
  height: 50px;
  justify-content: space-between;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

ul {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  list-style: none;
  margin: 0 20px 0 0;
}

ul li {
  margin-right: 10px;
  padding: 5px;
}

.input-form {
  display: flex;
  width: 280px;
}

.signOut-link {
  color: red;
  text-decoration: none;
}

.signOut-link:hover {
  color: #ee7879;
  transform: scale(0.9);
  transition: 0.1s;
}

.open .hamburger-bar-1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.open .hamburger-bar-2 {
  opacity: 0;
}

.open .hamburger-bar-3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-6px, -8px);
}

.open .mobile-menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mobile-menu {
  display: none;
  height: calc(100vh - 50px);
  left: 0;
  position: absolute;
  top: 50px;
  width: 100%;
  padding: 0;
}

@media only screen and (max-width: 1075px) {
  .brand {
    display: none;
  }

  .hamburger-icon {
    display: block;
    margin-right: 10px;
  }

  .nav-links {
    display: none;
  }

  .open .mobile-menu {
    align-items: flex-end;
    background-color: #333;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
  }

  .signOut-link {
    margin: 20px;
    font-size: 18px;
  }

  ul li {
    margin: 20px;
  }
}
