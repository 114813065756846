.card-header-container {
  display: flex;
  flex-direction: column;
  overflow-wrap: center;
  margin: 20px;
}

.card-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-name-text {
  font-size: 12px;
  inline-size: 200px;
  margin: 0 0 5px 0;
  overflow-wrap: break-word;
  width: 100%;
}
.set-name-text {
  color: #ee7879;
  font-size: 12px;
  inline-size: 200px;
  margin: 0 0 5px 0;
  overflow-wrap: break-word;
  width: 100%;
}

.flip-btn {
  background-color: #09c372;
  border-radius: 7px;
  color: black;
  margin: 0 0 0 3px;
  padding: 5px 10px 5px 10px;
}

.flip-btn:hover {
  background-color: #ee7879;
  color: white;
  transform: scale(0.9);
  transition: 0.1s;
}

.spinner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #09c372;
  border-radius: 7px;
  color: #ee7879;
  padding: 5px;
  margin: 10px;
}

.btn-row {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.spinner-btn {
  background-color: #333;
  border-radius: 7px;
  color: #09c372;
  font-weight: bold;
  width: 30px;
  height: 30px;
  justify-content: center;
  vertical-align: middle;
  margin: 3px;
}

.spinner-btn:hover {
  border: 1px solid #ee7879;
}

.spinner-text {
  color: #ee7879;
  margin: auto 5px auto 5px;
}
